import React from 'react'

const Servicecard = (props) => {
  return (
    <>

<div class='card_pricing col-12 col-md-4 col-lg-4' data-aos="fade-down"
          data-aos-duration="1500"
          data-aos-offset="200" >

<div className='card_pricing_content' >

  <h2>{props.title}</h2>

  <h4>{props.price}</h4>

</div>


<div>


  <ul>

    <li>{props.pages}</li>
    <li>{props.hosting}</li>
    <li>{props.icons} </li>
    <li>{props.admin}</li>
    <li>{props.support}</li>

    <li>{props.monthlysupport}</li>



  </ul>

</div>


</div>



    </>
  )
}

export default Servicecard
