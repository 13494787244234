import { FaPenFancy } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { LiaAddressBookSolid } from "react-icons/lia";
import { MdEmail } from "react-icons/md";
import { FaMobileAlt } from "react-icons/fa";
import axios from 'axios';
import React, { useState } from 'react';
import { AiFillAliwangwang } from "react-icons/ai";




const Getcom = () => {
 
 
 
 
  // form submission here

  const [formData, setFormData] = useState({
    name: '',
    address: '',
    email: '',
    mobile: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    axios.post("http://localhost:5001/Getcom", formData)
      .then(res => {
        console.log("Registered successfully");
        // Optionally, clear form fields after successful submission
        setFormData({
          name: '',
          address: '',
          email: '',
          mobile: ''
        });
      })
      .catch(err => {
        console.error("Error:", err);
        if (err.response) {
          console.error("Response data:", err.response.data);
        }
      });
  }








 
 
 
 
 
    return (
    <>
        <section className='form_container container_fluid '>

<div className='form_container_child container row'>

  <h2>get in touch &nbsp; <AiFillAliwangwang className='arrow_white' />
  </h2>

  <form onSubmit={handleSubmit} className='form_container_child_left col-12 col-md-6 col-lg-6'>
    <label htmlFor="name">Name:</label>

    <div className='input_box'>
      <FaUser className='form_icon' />

      <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} />

    </div>

    <label htmlFor="address">Address:</label>

    <div className='input_box'>

      <LiaAddressBookSolid className='form_icon' />
      <input type="text" id="address" name="address" value={formData.address} onChange={handleChange} />

    </div>

    <label htmlFor="email">Email:</label>

    <div className='input_box'>


      <MdEmail className='form_icon' />

      <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} />

    </div>

    <label htmlFor="mobile">Mobile:</label>


    <div className='input_box'>

      <FaMobileAlt className='form_icon' />

      <input type="text" id="mobile" name="mobile" value={formData.mobile} onChange={handleChange} />

    </div>

    <button type="submit">Submit</button>
  </form>

  <div className='form_container_child_right col-12 col-md-6 col-lg-6'>


    <div className='form_container_child_right_box'>
      <FaPenFancy className='pen' />

    </div>



  </div>


</div>

</section>

    </>
  )
}

export default Getcom
