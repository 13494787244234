import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './components/Home';
import Whoami from './components/Whoami';
import Clientsolutions from './components/Clientsolutions';
import Getsupport from './components/Getsupport';
import Getintouch from './components/Getintouch';
import Read from './components/Read';
import Header from './components/Header';
import Footer from './components/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import React, { useEffect } from 'react';


const App = () => {



  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 400, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    });
  }, []);













  // Use useLocation hook to get the current location
  const location = useLocation();

  // Define a list of routes where the header and footer should be included
  const includeHeaderFooterRoutes = ['/', '/Whoami', '/Clientsolutions', '/Getsupport', '/Getintouch'];

  // Check if the current location matches any of the routes that should include the header and footer
  const shouldIncludeHeaderFooter = includeHeaderFooterRoutes.includes(location.pathname);

  return (
    <>
      {/* Conditionally render the header and footer */}
      {shouldIncludeHeaderFooter && <Header />}
      <Routes>
        {/* Define routes */}
        <Route path="/" element={<Home />} />
        <Route path="/Whoami" element={<Whoami />} />
        <Route path="/Clientsolutions" element={<Clientsolutions />} />
        <Route path="/Getsupport" element={<Getsupport />} />
        <Route path="/Getintouch" element={<Getintouch />} />
        <Route path="/Read" element={<Read />} />
      </Routes>
      {shouldIncludeHeaderFooter && <Footer />}
    </>
  );
}

export default App;
