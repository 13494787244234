import axios from 'axios';
import React, { useState } from 'react';
import { FaPenFancy } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { LiaAddressBookSolid } from "react-icons/lia";
import { MdEmail } from "react-icons/md";
import { FaMobileAlt } from "react-icons/fa";
import { AiFillAliwangwang } from "react-icons/ai";
import Seo from './Seo';
import Getcom from './Getcom';




const Getsupport = () => {
  
  
  



// form submission here

const [formData, setFormData] = useState({
  name: '',
  address: '',
  email: '',
  mobile: ''
});

const handleChange = (e) => {
  setFormData({ ...formData, [e.target.name]: e.target.value });
}

const handleSubmit = (e) => {
  e.preventDefault();

  axios.post("http://localhost:5000/create", formData)
    .then(res => {
      console.log("Registered successfully");
      // Optionally, clear form fields after successful submission
      setFormData({
        name: '',
        address: '',
        email: '',
        mobile: ''
      });
    })
    .catch(err => {
      console.error("Error:", err);
      if (err.response) {
        console.error("Response data:", err.response.data);
      }
    });
}





  
  
  
  return (
   
   
   <>

<Seo
        title="NazimTech: Empowering Digital Innovation in India | Transforming Businesses with NazimTech: Leading Software Solutions in India | NazimTech: Driving Digital Growth and Excellence Across India"

        description="NazimTech is your ultimate destination for innovative software solutions in India. From CRM and web development to UI/UX design and SEO optimization, we're dedicated to driving digital excellence and empowering businesses across India."

        keywords="Software Solutions India
        CRM Solutions India
        Web Development Services India
        UI/UX Development India
        SEO Optimization Services India
        E-commerce Solutions India
        Digital Excellence India
        Innovative Software Solutions India
        Website Price Plans India
        NazimTech India"

        canonicalUrl="http://nazimtech.cloud/" // Replace with the canonical URL of your home page

      />

     
      {/* get in touch */}

 <Getcom/>
 
    </>
  )
}

export default Getsupport
