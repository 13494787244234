import React from 'react'

const Common = (props) => {
 
 
 
    return (
    <>
      

      {/* Hero section */}


      <section className='hero_section container-fluid '>


<div className='hero_section_child container row'>

<div className='hero_section_child_left col-12 col-lg-7 col-md-7' data-aos="fade-down"
          data-aos-duration="2000"
          data-aos-offset="200">

<h1>{props.title}</h1>


<p>{props.parh}</p>

</div>

<div className='hero_section_child_right col-12 col-lg-5 col-md-5'>

<img src={props.img} alt="" srcset="" />
</div>

</div>

        
      </section>

{/* sevices section */}




    </>
  )
}

export default Common
