// Footer.js
import React from 'react';
import { FaYoutube } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaArrowRight } from "react-icons/fa";
import { FaSquareWhatsapp } from "react-icons/fa6";
import { NavLink } from 'react-router-dom'; // Import useHistory hook

const Footer = () => {

 
  const emailAddress = 'zk8052272@gmail.com'; // Your email address

  return (
    <>
      {/* footer in touch */}
      <footer className='container-fluid footer_section '>
        <div className='footer_section_child container row'>
          <div className='col-12 col-lg-4 col-md-4 footer_section_child_subchild footer_section_child_subchild1'>
            <h5>About NazimTech</h5>
            <p>Empowering businesses across India, NazimTech is a leading provider of innovative digital solutions. Our mission is to drive growth and success through cutting-edge technology and unparalleled service. </p>
          </div>
          <div className='col-12 col-lg-4 col-md-4 footer_section_child_subchild'>
            <h4>important link</h4>
            <ul>

              <li>
                <NavLink to="/Whoami" className="active1">
                  <FaArrowRight />
                  &nbsp;who we are
                </NavLink>
              </li>

              <li>
                <NavLink to="/Clientsolutions" className="active1">
                  <FaArrowRight />
                  &nbsp;client solution
                </NavLink>
              </li>

              <li>
                <NavLink to="/Getsupport" className="active1">
                  <FaArrowRight />
                  &nbsp;get support
                </NavLink>
              </li>

              <li>
                <NavLink to="/Getsupport" className="active1">
                  <FaArrowRight />
                  &nbsp;lets talk
                </NavLink>
              </li>

            
            
            </ul>


          </div>
          <div className='col-12 col-lg-4 col-md-4 footer_section_child_subchild social_box'>
            <h4>social connect </h4>
            <ul >
              <li>
                <NavLink to="https://www.youtube.com/@Helmet_programmer" target="_blank" className="active1">
                  <FaYoutube />
                </NavLink>
              </li>
              <li>
                <NavLink to="https://www.instagram.com/nazimtech_officially?igsh=MXFsM2R5ejNxYmMxeQ==" target="_blank" className="active1">
                  <FaInstagramSquare />
                </NavLink>
              </li>
              <li>
                <NavLink to={`https://wa.me/${9113499324}`} target="_blank" className="active1">
                  <MdEmail />
                </NavLink>
              </li>
              <li>
                <NavLink to={`https://wa.me/${9113499324}`} target="_blank" className="active1">
                  <FaSquareWhatsapp />
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <section className="subfooter_section container row">
          <h6>&copy; 2024 NazimTech. All rights reserved. | Crafted with by NazimTech Team</h6>
        </section>
      </footer>
    </>
  )
}

export default Footer;
