import { FaRegArrowAltCircleDown } from "react-icons/fa";
import { FaLongArrowAltDown } from "react-icons/fa";
import Common from './Common';
import Seo from './Seo';
import Servicecard from "./Servicecard";
import Servicecom from './Servicecom';






const clientsolutions = () => {
 
 

  return (
   
   <>


<Seo
        title="NazimTech: Empowering Digital Innovation in India | Transforming Businesses with NazimTech: Leading Software Solutions in India | NazimTech: Driving Digital Growth and Excellence Across India"

        description="NazimTech is your ultimate destination for innovative software solutions in India. From CRM and web development to UI/UX design and SEO optimization, we're dedicated to driving digital excellence and empowering businesses across India."

        keywords="Software Solutions India
        CRM Solutions India
        Web Development Services India
        UI/UX Development India
        SEO Optimization Services India
        E-commerce Solutions India
        Digital Excellence India
        Innovative Software Solutions India
        Website Price Plans India
        NazimTech India"

        canonicalUrl="http://nazimtech.cloud/" // Replace with the canonical URL of your home page

      />





     {/* Hero section */}

 <Common  

title="Welcome to NazimTech: Where Innovation Takes Flight!"

 parh="your ultimate destination for groundbreaking software solutions in India! We're not just another software startup; we're a hub of creativity, innovation, and unrivaled expertise. Come, be a part of our journey as we redefine the future of technology in India!"

 img="https://cdn.dribbble.com/users/2131993/screenshots/4948736/thoughtworks-gif_dribbble.gif"


/>

      


      {/* services provide */}

     <Servicecom/>
    

    

      {/* pricing provide */}

      <section class='pricing_section container-fluid '>

<div className='title_pricing row container'>
<h2>Website Price Plans   &nbsp;<FaLongArrowAltDown className='arrow_white'/>
 </h2>
</div>

  <div class='pricing_section_child row container '>

<div className='pricing_section_child_scroll'>


   
<Servicecard

title="Basic "
price="$ 200 "
pages="2 Pages
"
hosting="Hosting+Domain For 1 Year"

icons="Social Icon

"

admin="Admin Panel
"
support="Software Support Free For 3 Months
"
monthlysupport="Monthly Support Charge 40 $ Months
"


/>


<Servicecard

title="Standard "
price="$ 350 "
pages="5 Pages
"
hosting="Hosting+Domain For 1 Year"

icons="Social Icon

"

admin="Admin Panel
"
support="Software Support Free For 3 Months
"
monthlysupport="Monthly Support Charge 60 $ Months
"

/>


<Servicecard

title="Premium
"
price="$ 550 "
pages="8 Pages
"
hosting="Hosting+Domain For 1 Year"

icons="Social Icon

"

admin="Admin Panel
"
support="Software Support Free For 3 Months
"
monthlysupport="Monthly Support Charge 100 $ Months
"

/>

<Servicecard

title="SEO "
price="$ 40 "
pages="8 Pages
"
hosting="Hosting+Domain For 1 Year"

icons="Social Icon

"

admin="Admin Panel
"
support="Software Support Free For 3 Months
"
monthlysupport="Monthly Support Charge 20 $ Months
"

/>


    </div>


    </div>


</section>



    </>
  )
}

export default clientsolutions
