
import Common from './Common';
import Seo from './Seo';
import Getcom from './Getcom';
import Aboutcom from './Aboutcom';






const Whoami = () => {
 
 
 


 
  return (
    <>

<Seo
        title="NazimTech: Empowering Digital Innovation in India | Transforming Businesses with NazimTech: Leading Software Solutions in India | NazimTech: Driving Digital Growth and Excellence Across India"

        description="NazimTech is your ultimate destination for innovative software solutions in India. From CRM and web development to UI/UX design and SEO optimization, we're dedicated to driving digital excellence and empowering businesses across India."

        keywords="Software Solutions India
        CRM Solutions India
        Web Development Services India
        UI/UX Development India
        SEO Optimization Services India
        E-commerce Solutions India
        Digital Excellence India
        Innovative Software Solutions India
        Website Price Plans India
        NazimTech India"

        canonicalUrl="http://nazimtech.cloud/" // Replace with the canonical URL of your home page

      />


      {/* Hero section */}

      <Common  

title="Welcome to NazimTech: Where Innovation Takes Flight!"

 parh=" your ultimate destination for groundbreaking software solutions in India! We're not just another software startup; we're a hub of creativity, innovation, and unrivaled expertise. Come, be a part of our journey as we redefine the future of technology in India!"

 img="https://cdn.dribbble.com/users/2131993/screenshots/4948736/thoughtworks-gif_dribbble.gif"


/>


      {/* about section */}

<Aboutcom/>



 {/* get in touch */}


<Getcom/>


    </>
  )
}

export default Whoami
