// Read.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Read = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getUsers();
  }, []);



  const getUsers = async () => {
    try {
      const response = await axios.get('http://localhost:5001/Read');
      setUsers(response.data || []);
    } catch (error) {
      console.error('Error getting users:', error);
    }
  };


  const deleteUser = async (userId) => {
    try {
      await axios.delete(`http://localhost:5001/Delete/${userId}`);
      // After deleting the user, fetch the updated user list
      getUsers();
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };



  return (
    
    <div className='read_container container-fluid'>

      <div className='container row read_container_title'>

      <h2>Dashboard</h2>

      </div>
     


      <table className='read_container_child row container '>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Address</th>
            <th>Mobile</th>
            <th>Action</th> {/* Add a new column for the Delete button */}
          </tr>




          {users.map(user => (
          


          
          <tr key={user.id} className='data_ret'>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.address}</td>
              <td>{user.mobile}</td>
              <td>
                <button onClick={() => deleteUser(user.id)} className='button_delete'>Delete</button>
              </td>
            </tr>



          ))}


      </table>
    </div>
  );
};

export default Read;
