// components/SEO.js

import React from 'react';
import { Helmet } from 'react-helmet';

const Seo = ({ title, description, keywords, canonicalUrl }) => {
  return (
    <Helmet>
          <meta name="robots" content="index, follow" /> {/* Assuming you want search engines to index and follow your site */}

      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      <link rel="canonical" href={canonicalUrl} /> {/* Replace with your canonical URL */}
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />


      {/* Mobile-friendly meta tags */}
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />

      {/* Organization schema markup */}
      <script type="application/ld+json">
        {`
          {
            "@context": "http://schema.org",
  "@type": "software development",
  "name": "NazimTech",
  "url": "http://nazimtech.cloud/",
  "logo": "http://nazimtech.cloud/l.jpg"
          }
        `}
      </script>
      {/* Add more meta tags as needed */}
    </Helmet>
  );
};

export default Seo;
