import React from 'react'
import { FaRegArrowAltCircleDown } from "react-icons/fa";
import img from './img/web.png'
import web1 from './img/app.png'
import seo from './img/seo.png'



const Servicecom = () => {
  return (
    <>
       <section className='service_provide container-fluid '>

<div className='title_service  container row'>

  <div className='title_service_child col-12 col-lg-12 col-md-12 ' data-aos="fade-down"
          data-aos-duration="3000"
          data-aos-offset="200">



    <h2>Our Commitment to Excellence <FaRegArrowAltCircleDown className='arrow_white' /></h2>

    <p>At NazimTech, we are dedicated to delivering top-notch services that exceed your expectations. Our mission is to empower businesses across India with innovative software solutions and unparalleled customer satisfaction.</p>

    <p>Key aspects of our commitment:</p>

    <ul>
      <li><strong>Quality Deliverables:</strong> We strive for excellence in every project, ensuring that our services meet the highest standards and provide tangible value to our clients.</li>
      <li className='pt-3 pt-md-3 pt-lg-3'><strong>Transparent Communication:</strong> We believe in open and transparent communication, keeping you informed at every stage of the project. Your satisfaction is our priority, and we're here to address any questions or concerns promptly.</li>
    </ul>

  </div>


</div>

<div className='row container service_provide_child'>






  <div className='sevice_card_left col-12 col-lg-8 col-md-8'     
  data-aos="fade-down"
  data-aos-duration="1500"
  data-aos-offset="200"
>

    <h4>#CRM Solutions Services in India <FaRegArrowAltCircleDown className='arrow_white' /></h4>

    <p>At NazimTech, we offer comprehensive CRM (Customer Relationship Management) solutions tailored to the unique needs of your business. Our services include:</p>

    <ul>
      <li><strong>Customized CRM Software:</strong> We develop bespoke CRM software solutions designed to streamline your business operations and enhance customer relationships.</li>
      <li className='pt-3 pt-md-3 pt-lg-3'><strong>CRM Implementation and Integration:</strong> Our experts assist you in implementing and integrating CRM systems seamlessly into your existing infrastructure, ensuring a smooth transition and maximum efficiency.</li>
      <li className='pt-3 pt-md-3 pt-lg-3'><strong>Training and Support:</strong> We provide training and ongoing support to ensure that your team fully utilizes the CRM system and maximizes its potential to drive growth and profitability.</li>
    </ul>

    <p>Key features of our CRM solutions:</p>

    <ul>
      <li><strong>Scalability:</strong> Our CRM solutions are scalable to accommodate the evolving needs of your business, whether you're a startup or a large enterprise.</li>
      <li className='pt-3 pt-md-3 pt-lg-3'><strong>Customization:</strong> We customize our CRM software to align with your specific industry requirements and business processes, ensuring optimal performance and efficiency.</li>
    </ul>



  </div>

  <div className='service_card_right col-12 col-lg-4 col-md-4' >

    <div className='service_card_right_content'>

    <img src="https://cdn-icons-png.flaticon.com/512/2464/2464130.png" alt="" />

    </div>

  </div>






</div>



<div className='row container service_provide_child'>






  <div className='sevice_card_left col-12 col-lg-8 col-md-8' data-aos="fade-down"
          data-aos-duration="1500"
          data-aos-offset="200">

    <h4>#Web Development Services in India <FaRegArrowAltCircleDown className='arrow_white' /></h4>

    <p>At NazimTech, we specialize in providing cutting-edge web development services tailored to meet the unique needs of businesses in India. Our comprehensive services include:</p>

    <ul>
      <li><strong>Custom Website Design:</strong> We create stunning, user-friendly websites that reflect your brand identity and resonate with your target audience.</li>
      <li className='pt-3 pt-md-3 pt-lg-3'><strong>Responsive Web Development:</strong> Our websites are optimized for seamless performance across all devices, ensuring an exceptional user experience on desktops, tablets, and smartphones.</li>
      <li className='pt-3 pt-md-3 pt-lg-3'><strong>E-commerce Solutions:</strong> We develop robust e-commerce platforms that drive sales and enhance customer engagement, helping businesses thrive in the competitive Indian market.</li>
    </ul>

    <p>Key features of our web development services:</p>

    <ul>
      <li><strong>SEO Optimization:</strong> We implement best practices for search engine optimization (SEO) to improve your website's visibility and rankings on search engines like Google, helping you attract more organic traffic.</li>
      <li className='pt-3 pt-md-3 pt-lg-3'><strong>Localized Content:</strong> Our content strategy focuses on creating localized content that resonates with Indian audiences, ensuring relevance and engagement.</li>
      <li className='pt-3 pt-md-3 pt-lg-3'><strong>Quality Assurance:</strong> We adhere to strict quality control measures to deliver polished and professional-looking websites that meet the highest standards of excellence.</li>
    </ul>



  </div>

  <div className='service_card_right col-12 col-lg-4 col-md-4'>

    <div className='service_card_right_content webdevelopment_design'>

      <img src={img} alt="" />

    </div>

  </div>






</div>


<div className='row container service_provide_child'>






  <div className='sevice_card_left col-12 col-lg-8 col-md-8' data-aos="fade-down"
          data-aos-duration="1500"
          data-aos-offset="200">

    <h4>#UI/UX Development Services in India <FaRegArrowAltCircleDown className='arrow_white' /></h4>

    <p>At NazimTech, we specialize in providing top-notch UI/UX development services tailored to the diverse needs of businesses in India. Our comprehensive services include:</p>

    <ul>
      <li><strong>User-Centric Design:</strong> We prioritize user experience (UX) and user interface (UI) design to create intuitive, engaging, and visually appealing digital experiences that resonate with Indian audiences.</li>
      <li className='pt-3 pt-md-3 pt-lg-3'><strong>Mobile App Design:</strong> Our experts design sleek and functional mobile app interfaces optimized for seamless navigation and enhanced user engagement on both Android and iOS platforms.</li>
      <li className='pt-3 pt-md-3 pt-lg-3'><strong>Website Optimization:</strong> We optimize website UI/UX to improve usability, accessibility, and conversion rates, ensuring a positive experience for visitors and driving business growth.</li>
    </ul>

    <p>Key features of our UI/UX development services:</p>

    <ul>
      <li><strong>Accessibility Compliance:</strong> We ensure that our designs comply with accessibility standards, making them accessible to all users, including those with disabilities, in compliance with Indian regulations.</li>
      <li className='pt-3 pt-md-3 pt-lg-3'><strong>Cultural Sensitivity:</strong> Our designs are culturally sensitive and tailored to the preferences and expectations of Indian users, resulting in greater acceptance and adoption.</li>
      <li className='pt-3 pt-md-3 pt-lg-3'><strong>Continuous Improvement:</strong> We follow an iterative design process, gathering feedback and making refinements to optimize the UI/UX and deliver exceptional digital experiences that exceed expectations.</li>
    </ul>


  </div>

  <div className='service_card_right col-12 col-lg-4 col-md-4'>

    <div className='service_card_right_content web_design'>
    <img src={web1} alt="" />
    </div>

  </div>






</div>

<div className='row container service_provide_child'>






  <div className='sevice_card_left col-12 col-lg-8 col-md-8' data-aos="fade-down"
          data-aos-duration="1500"
          data-aos-offset="200">

    <h4>#Web Development Services Optimized for SEO in India</h4>
    <p>Unlock the potential of your online presence with our tailored web development services optimized for SEO in India. Attracting and engaging your target audience begins with a solid digital foundation, and we're here to ensure your website stands out amidst the competition.</p>
    <p>Our services encompass custom website design, responsive development, and robust e-commerce solutions, all crafted to reflect your brand identity and drive conversions. With a keen eye for detail, we ensure every aspect of your website aligns with your specifications, enhancing user experience and satisfaction.</p>
    <p>When it comes to SEO, we implement industry-leading practices to boost your website's visibility and rankings on search engines. From keyword optimization to localized content strategies tailored for Indian audiences, we ensure your website is poised for success in the competitive online landscape.</p>
    <p>Partner with us to elevate your online presence and achieve your business goals in the dynamic Indian market.</p>


  </div>

  <div className='service_card_right col-12 col-lg-4 col-md-4'>

    <div className='service_card_right_content web_design'>
      <img src={seo} alt="" />
    </div>

  </div>






</div>


</section>


    </>
  )
}

export default Servicecom
