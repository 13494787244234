import React from 'react'
import { FaLongArrowAltDown } from "react-icons/fa";
import { NavLink } from 'react-router-dom';



const Aboutcom = () => {
 
    const AboutLink = () => {
        return {
            textDecoration: 'none',
            color: 'white',
           
            
        };
    };
    
 
 
 
 
    return (
 
 <>
      {/* about section */}


      <section className='about_section container-fluid '>

        <div className='about_section_child row container'>

          <div className='about_section_child_left col-12 col-lg-8 col-md-8' data-aos="fade-down"
          data-aos-duration="2500"
          data-aos-offset="200">

            <h2> Leading India Towards Technological Advancement!  &nbsp;<FaLongArrowAltDown className='arrow_white' /></h2>

            <p> your ultimate partner for cutting-edge software solutions in India! We're not just another software company; we're a catalyst for innovation, dedicated to propelling India towards digital excellence.</p>

          </div>

          <div className='about_section_child_right col-12 col-lg-4 col-md-4'>


<NavLink to="/Getintouch" style={AboutLink()}>

<div className='button_talkus'>
              <h4>talk us</h4>

            </div>

</NavLink>
          


          </div>


        </div>




      </section>
 
    </>
  )
}

export default Aboutcom
