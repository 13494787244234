/* global $ */



import { NavLink } from 'react-router-dom';
import { TfiAlignRight } from "react-icons/tfi";
import { useState } from 'react';



const Header = () => {
 
 

  const [isNavVisible, setIsNavVisible] = useState(false);

  const toggleNavVisibility = () => {
    setIsNavVisible(!isNavVisible);
  };


 
  return (
    
    
    <header className='header container-fluid'>

      <div className='row container header_child'>

        <div className='header_left col-12 col-md-4 col-lg-4'>

          <h5 data-aos="fade-down"
          data-aos-duration="1000"
          >NazimTech</h5>

          <TfiAlignRight  className='navbar_icon' onClick={toggleNavVisibility}/>


        </div>

        <nav className={`col-12 col-lg-8 col-md-8 header_right ${isNavVisible ? 'visible' : ''}`} data-aos="fade-down"
          data-aos-duration="1500"
          >

          <ul className='nav_list'>

            <li><NavLink to="/" exact className="active1" onClick={toggleNavVisibility}>Home</NavLink></li>


            <li><NavLink to="/Whoami" className="active1" onClick={toggleNavVisibility}>Who We Are</NavLink></li>

            <li><NavLink to="/Clientsolutions" className="active1" onClick={toggleNavVisibility}>Client Solutions</NavLink></li>

            <li><NavLink to="/Getsupport" className="active1" onClick={toggleNavVisibility}>Get Support</NavLink></li>

            <li><NavLink to="/Getintouch" className="active1" onClick={toggleNavVisibility}>Let's Talk</NavLink></li>

          </ul>
        </nav>


      </div>


    </header>
  );
}

export default Header;
