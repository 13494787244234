import React from 'react'

const Countercom = () => {
  return (
  
  <>
        <section className='container-fluid couter_section ' >

<div className='container row counter_section_child' data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-offset="200">

  <div className='counter_section_child_card col-12  col-lg-4 col-md-4'>
    <h2>45+</h2>
    <h4>Projects Delivered</h4>
    <p>With a proven track record of delivering over 45 successful projects, we pride ourselves on our commitment to excellence and client satisfaction.</p>
  </div>

  <div className='counter_section_child_card col-12  col-lg-4 col-md-4'>
    <h2>41+</h2>
    <h4>Happy Clients</h4>
    <p>We've earned the trust of more than 41 satisfied clients who appreciate our dedication to quality, reliability, and timely delivery.</p>

  </div>

  <div className='counter_section_child_card col-12  col-lg-4 col-md-4'>
    <h2>3+</h2>
    <h4>Years of Experience</h4>
    <p>With over 3 years of industry experience, we bring a wealth of knowledge and expertise to every project we undertake.</p>
  </div>


</div>


</section>


    </>
  )
}

export default Countercom
